import * as React from "react"
import { Helmet } from "react-helmet"
import Navbar from "./navbar"
import Footer from './footer'

const Layout = ({ children }) => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Villa340 - Student Housing</title>
                <meta name="description" content="Student and young professional housing in Carcavelos and Paço d'Arcos"></meta>
            </Helmet>
            <Navbar></Navbar>
            {children}
            <Footer></Footer>
        </div>
    )
}

export default Layout