import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Navbar as BNavbar, Nav, NavDropdown, Button } from "react-bootstrap"
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';

const Navbar = () => {
    const { t } = useTranslation();
    const { changeLanguage } = useI18next();

    const data = useStaticQuery(
        graphql`
            query {
                properties: allMarkdownRemark(filter: {frontmatter: {type: {eq: "property"}}}) {
                    nodes {
                        id
                        frontmatter {
                            title
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
            `
    )

    const properties = data.properties.nodes

    const langBtnStyle = {
        fontSize: 'normal',
        padding: '5px'
    }

    return (
        <header>
            <BNavbar collapseOnSelect expand="md" bg="light">
                <BNavbar.Brand href="/">
                    <h3 className="logo">Villa340</h3>
                </BNavbar.Brand>
                <BNavbar.Toggle aria-controls="basic-navbar-nav" />
                <BNavbar.Collapse>
                    <Nav className="mr-auto ml-3">
                        <NavDropdown title={t("properties")}>
                            {
                                properties.map(p => (
                                    <NavDropdown.Item key={p.fields.slug}>
                                        <Link to={p.fields.slug}>
                                            {p.frontmatter.title}
                                        </Link>
                                    </NavDropdown.Item>
                                ))
                            }
                        </NavDropdown>

                        <Nav.Item
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <div>
                                <Button onClick={() => changeLanguage('en')} variant="link" style={langBtnStyle}>EN</Button>
                                <span>|</span>
                                <Button onClick={() => changeLanguage('pt')} variant="link" style={langBtnStyle}>PT</Button>
                            </div>
                        </Nav.Item>
                    </Nav>
                </BNavbar.Collapse>
            </BNavbar>
        </header>
    )
}

export default Navbar