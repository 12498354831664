import * as React from "react"

const Footer = () => {
    return (
        <footer
            style={{
                padding: '20px 30px',
                color: 'white',
                minHeight: '40px',
                backgroundColor: 'black'
            }}
        >
            <div style={{
                fontSize: 'small',
                margin: 'auto',
                maxWidth: '300px',
                textAlign: 'center'
            }}>
                Villa340 - geral.chiado21@gmail.com
                <br></br>
                <br></br>
                Made by Manuel Maldonado
            </div>        
            
        </footer>
    )
}

export default Footer