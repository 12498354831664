export function transformCloudinaryURL(url, { w, q }) {
    let params = []
    params.push(q ? `q_${q}` : 'q_80')
    if (w) params.push(`w_${w}`)

    const splitUrl = url.split('/upload')
    return `${splitUrl[0]}/upload/c_scale,${params.join(',')}${splitUrl[1]}`
}

export const availabilityToLocalizedString = (t, lang) => date => {
    if(typeof date == 'string') date = new Date(date)
    
    if (date < Date.now()) {
        return t('availableNow')
    } else {
        const formattedDate = date.toLocaleDateString(
            lang,
            {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            }
        )
        return t('availableFrom') + ' ' + formattedDate
    }
}